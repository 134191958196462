/* @import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"); */

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "futura";
  src: url("./fonts/ember.ttf") format("truetype");
}

html,
body {
  scroll-behavior: smooth;
}

html {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background-color: #ffffff;
}

body {
  margin: 0;
  font-family: "Futura", sans-serif;
  background-color: #ffffff;
  color: black;
  padding: 0;
  overflow-x: hidden;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.separator {
  display: inline-block;
  height: 32px; /* Adjust height as needed */
  width: 1px;
  background-color: black;
  margin: 0 8px; /* Adjust margin as needed */
}

.custom-phone-input {
  /* Remove default styling */
  border: none !important;
  background: none !important;
  box-shadow: none !important;
}

.custom-phone-input input {
  /* Remove border from the input field */
  border: none !important;
  background: none !important;
  box-shadow: none !important;
}

.custom-phone-input .PhoneInputCountrySelect {
  /* Style the country select dropdown if needed */
  background: none !important;
}

.prose strong{
  font-weight: 700;
}

.prose a {
  color: #f50057;
  text-decoration: none;
}

.prose a:hover {
  text-decoration: underline;
}

.prose h1 {
  font-size: 2.5rem;
  font-weight: 700;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}

.prose h2 {
  font-size: 2rem;
  font-weight: 700;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}

.prose h3 {
  font-size: 1.75rem;
  font-weight: 700;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}

.prose h4{
  font-size: 1.5rem;
  font-weight: 700;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}

.prose h5{
  font-size: 1.25rem;
  font-weight: 700;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}

.prose h6{
  font-size: 1rem;
  font-weight: 700;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}

.prose p {
  font-size: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.prose ul {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.prose ol {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.prose li{
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.prose blockquote {
  font-size: 1.25rem;
  font-style: italic;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding-left: 1rem;
  border-left: 4px solid #f50057;
}

.prose code {
  font-size: 1rem;
  background-color: #f3f4f6;
  padding: 0.25rem;
  border-radius: 0.25rem;
}

.prose i{
  font-style: italic; 
}

