.table-container {
  width: 100%;
  overflow-x: auto;
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 18px;
  text-align: left;
}

.table th,
.table td {
  padding: 12px 15px;
  border: 1px solid #ddd;
}

.table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}
