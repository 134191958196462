.TextShimmer {
    position: relative;
    overflow: hidden;
    background-color: #b0b0b0; /* Light gray as the base color */
  }
  
  .TextShimmer::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
        90deg,
        rgba(176, 176, 176, 0) 0,    /* Transparent light gray */
        rgba(176, 176, 176, 0.2) 20%, /* Slightly lighter gray */
        rgba(150, 150, 150, 0.5) 60%, /* A medium gray */
        rgba(176, 176, 176, 0)        /* Transparent light gray */
    );
    animation: shimmer 2s infinite;
    content: '';
  }
  
  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
  